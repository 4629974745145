import React from 'react'
import './Footer.css'
function Footer() {
    return (
        <div className='Footer'>
            <p>Content copyright 2019. Hooper Irrigation Co. All rights reserved.</p>
        </div>
    )
}

export default Footer
